import React from 'react';
import { Link } from 'gatsby';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { spacing } from 'styles';

import {
  Button,
  SEO,
  Subtitle,
  Description,
} from 'components';

import Layout from 'layouts/base';

const NotFoundContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${spacing.chapter * 2}px;
  height: 100vh;
  min-height: 560px;
  box-sizing: border-box;
  text-align: center;
`;

const FakeWrapper = styled.div`
  width: 100%;
`;

const NotFoundHeader = styled.div`
  margin-bottom: ${spacing.section}px;
`;

const iframeStyle = css`
  display: block;
  margin: 0 auto;
  width: 40vh;
  min-width: 300px;
  height: 30vh;
  min-height: 225px;
  margin-bottom: ${spacing.chapter}px;
  border: none;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO
      title='페이지를 찾을 수 없습니다'
    />
    <NotFoundContainer>
      <FakeWrapper>
        <NotFoundHeader>
          <Subtitle>
            페이지를 찾을 수 없습니다
          </Subtitle>
          <Description
            css={css`
              padding: 0 6%;
              word-break: keep-all;
            `}
          >
            입력하신 페이지가 삭제되었거나 주소가 올바르지 않아, 해당 페이지를 찾을 수 없습니다.
            <br />
            주소를 다시 한 번 확인해주세요.
          </Description>
        </NotFoundHeader>
        <iframe
          css={iframeStyle}
          src='https://www.youtube.com/embed/dQw4w9WgXcQ?controls=0&autoplay=1'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        />
        <Button
          as={Link}
          to='/'
          size='small'
        >
          홈으로 이동하기
        </Button>
      </FakeWrapper>
    </NotFoundContainer>
  </Layout>
);

export default NotFoundPage;

